<template>
  <div class="navRight">
    <div class="navRightTop">
      <div class="title">
        <span @click="activeTab = '巡检数据'"
          >{{ t('156') }}&nbsp;&nbsp;/&nbsp;&nbsp;</span
        >
        <span style="margin-right: 1.25rem; cursor: default">{{
          activeTab === "三维图层" ? t("816") : t("693")
        }}</span>

        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-show="
              fileList[activeTab] !== undefined &&
              fileList[activeTab].length !== 0
            "
          >
            <span @click="fileIndex(folderHeadId[activeTab])"
              >:&nbsp;{{ t('813') }}</span
            >
          </el-breadcrumb-item>
          <el-breadcrumb-item
            v-for="item in fileList[activeTab]"
            :key="item"
            @click="fileIndex(item.folderId)"
            ><span class="overflow_text" :title="item.folderName">{{ item.folderName.length>15?item.folderName.slice(0,15)+'...': item.folderName}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div>
        <div v-show="activeTab === '巡检数据'" class="operation">
          <div @click="paste" v-checkAuth="'data-asset-paste'">
            <span class="iconfont">&#xe780;</span>
            <span>{{ t('695') }}</span>
          </div>
          <div @click="copy">
            <span class="iconfont"> &#xe61b; </span>
            <span>{{ t('814') }}</span>
          </div>
          <div style="margin-right: 1.3125rem" @click="exportData" v-checkAuth="'data-asset-export'">
            <el-icon class="element_icon"> <Upload /> </el-icon>&nbsp;{{ t('647') }}
          </div>
        </div>

        <div
          v-show="activeTab === '三维图层'"
          class="operation"
          style="margin-right: 1.3125rem"
        >
          <div @click="isCreateVisible = true">
            <el-icon class="element_icon"> <CirclePlusFilled /> </el-icon
            >&nbsp;{{ t('815') }}
          </div>
        </div>

        <div class="operation">
          <div @click="importFile" v-checkAuth="activeTab === '三维图层'?'data-asset-import':'data-layer-import'">
            <el-icon class="element_icon"> <Download /> </el-icon>&nbsp;{{ t('648') }}
          </div>
          <div @click="delectData(activeTab)" v-checkAuth="activeTab === '三维图层'?'data-asset-delete':'data-layer-delete'">
            <el-icon color="red"><Delete /></el-icon>
            <span style="color: red"> &nbsp;{{ t('210') }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 右底筛选框 -->
    <div class="navRightBom">
      <button
        @click="changeTab('巡检数据')"
        style="margin-right: 0; border-radius: 0.25rem 0px 0px 0.25rem"
        :class="{ activer: activeTab === '巡检数据' }"
      >
        {{ t('693') }}
      </button>
      <button
        @click="changeTab('三维图层')"
        :class="{ activer: activeTab === '三维图层' }"
      >
        {{ t('816') }}
      </button>
      <el-input
        :placeholder="t('780')"
        v-model.trim="keyword"
        clearable
        @clear="selectKeyword"
        @keyup.enter="selectKeyword"
      />
      <button class="select" @click="selectKeyword">
        <el-icon size="1.5rem"><Search /></el-icon>
      </button>

      <div v-show="activeTab === '三维图层'">
        <span class="fileTitle">{{ t('817') }}：</span>
        <el-select
          v-model="screenType.layerType"
          :placeholder="t('175')"
          clearable
          style="width: 11.25rem"
        >
          <el-option
            v-for="item in fileOptions"
            :key="item.value"
            :label="item.desc"
            :value="item.value"
          />
        </el-select>
      </div>

      <!-- 筛选弹出栏 -->
      <!-- @before-enter="clearSelect"
        @after-leave="patrolInit" -->
      <el-popover
        placement="bottom-end"
        trigger="click"
        popper-class="nopadding_popover"
        :show-arrow="false"
        v-if="activeTab === '巡检数据'"
      >
        <template #reference>
          <img class="hover_opacity" src="@/asset/img/shoudiantong.png" />
        </template>
        <div class="popover_content">
          <div class="content_top">
            <p>{{ t('817') }}:</p>
            <el-select
              v-model="patrolScreen.dataType"
              :placeholder="t('175')"
              :teleported="false"
              clearable
              @clear="clearType"
            >
              <el-option
                v-for="item in typesDropdown.fileTypeList"
                :key="item.value"
                :label="item.desc"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="line"></div>
          <div class="content_top">
            <p>
              <select
                v-model="typesDropdown.typesValue"
                class="selection"
                @change="getSourceList"
              >
                <option
                  v-for="item in typesDropdown.typesList"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                  style="color: black"
                ></option>
              </select>
            </p>
            <el-select
              v-model="patrolScreen.builderId"
              clearable
              :teleported="false"
            >
              <el-option
                v-for="item in typesDropdown.sourceList"
                :key="item.builderId"
                :label="item.laber"
                :value="item.builderId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="line"></div>
          <div class="content_bottom">
            <p>{{ t('799') }}:</p>
            <div class="enter-date-picker">
              <div class="block">
                <el-date-picker
                  v-model="patrolScreen.startTime"
                  type="datetime"
                  :placeholder="t('774')"
                  :teleported="false"
                  style="width: 23.125rem"
                  format="YYYY/MM/DD hh:mm:ss"
                  value-format="YYYY/MM/DD hh:mm:ss"
                />
              </div>
            </div>
            <p class="endtime">{{ t('800') }}:</p>
            <div class="enter-date-picker">
              <div class="block">
                <el-date-picker
                  v-model="patrolScreen.endTime"
                  type="datetime"
                  :placeholder="t('774')"
                  :teleported="false"
                  style="width: 23.125rem"
                  format="YYYY/MM/DD hh:mm:ss"
                  value-format="YYYY/MM/DD hh:mm:ss"
                />
              </div>
            </div>
          </div>
        </div>
      </el-popover>
    </div>
  </div>
  <div class="context">
    <keep-alive>
      <patrolTable
        ref="patrolTbale"
        v-if="activeTab === '巡检数据'"
        :patrolScreen="patrolScreen"
        :keyword="keyword"
        :fileTypeList="typesDropdown.fileTypeList"
        @changeFolderId="changeFolderId"
      ></patrolTable>
    </keep-alive>

    <!-- 图层表格 -->
    <keep-alive>
      <layerTable
        ref="layerTable"
        :keyword="keyword"
        :screenType="screenType"
        v-if="activeTab === '三维图层'"
        @changeFolderId="changeFolderId"
      ></layerTable>
    </keep-alive>

    <!-- 导入文件-->
    <import-file
      v-if="isVisible"
      :folderId="
        activeTab === '巡检数据' ? patrolScreen.folderId : screenType.folderId
      "
      v-model:isVisible="isVisible"
      :importDropList="importDropList"
      @layerInit="layerInit"
      @patrolInit="patrolInit"
      :types="activeTab"
    ></import-file>

    <!-- 创建文件夹 -->
    <ele-dialog
      v-model:isVisible="isCreateVisible"
      :dialogTitle="createFolderData.dialogTitle"
      @on-cancel="isCreateVisible = false"
      @on-add="createFolder"
    >
      <el-form
        ref="elFormRef"
        label-position="top"
        :model="createFolderData"
        class="fileForm"
        :rules="nameRules"
      >
        <el-form-item :label="t('779')" prop="creatName">
          <el-input
            :placeholder="t('780')"
            v-model="createFolderData.creatName"
            @keyup="
              createFolderData.creatName = createFolderData.creatName.replace(
                /[ ]/g,
                ''
              )
            "
            maxlength="64"
          ></el-input>
        </el-form-item>
      </el-form>
    </ele-dialog>
  </div>
</template>
<script>
import { t } from '../../../languages';
import {
  defineComponent,
  nextTick,
  onBeforeMount,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import { Search, Delete, Upload, Download } from "@element-plus/icons-vue";
import importFile from "./components/importFile.vue";
import dataServer from "../../../network/data";
import layerTable from "./components/layerTable.vue";
import { ElMessage } from "element-plus";
import patrolTable from "./components/patrolTable.vue";
export default defineComponent({
  components: { importFile, patrolTable, layerTable },
  setup() {
    onBeforeMount(() => {
      getSourcetypes();
      getLayerDropdown();
      getFolderHeadId();
      getImportFilelist();
    });

    let folderHeadId = reactive({});
    async function getFolderHeadId() {
      let res = await dataServer.getFolderHeadId();
      if (res instanceof Array) {
        res.forEach((item) => {
          folderHeadId[item.desc] = item.value.toString();
          item.desc === "三维图层" &&
            (screen.screenType.folderId = item.value.toString());
          item.desc !== "三维图层" &&
            (screen.patrolScreen.folderId = item.value.toString());
        });
      }
    }

    /* Tab栏切换 */
    let activeTab = ref("巡检数据");
    function changeTab(tab) {
      activeTab.value = tab;
    }
    watch(activeTab, (newVlue) => {
      //切换初始化筛选
      screen.keyword = "";
      if (newVlue === "三维图层") {
        screen.screenType.layerType = "";
        screen.importDropList = screen.fileOptions;
      }
      if (newVlue === "巡检数据") {
        screen.importDropList = screen.typesDropdown.fileTypeList;
        for (let item in screen.patrolScreen) {
          item !== "folderId" && (screen.patrolScreen[item] = ""); //清除筛选条件
        }
      }
    });

    /* 筛选功能 */
    const screen = reactive({
      importDropList: [], //导入下拉框
      fileOptions: [], //下拉框数据
      screenType: {
        //图层
        layerType: "", //选择文件类型
        folderId: "", //图层文件夹id
      },

      keyword: "", //文件名称筛选

      patrolScreen: {
        //巡检
        folderId: "", //文件夹id
        builderId: "", //下拉框所属id
        dataType: "", //类型
        endTime: "",
        startTime: "",
      },
      typesDropdown: {
        //数据下拉列表
        typesList: [],
        typesValue: "",
        sourceList: [],
        fileTypeList: [],
      },
      fileList: {}, //文件夹面包屑
    });

    //点击选择文件夹面包屑
    function fileIndex(folderId) {
      getFileIndex(folderId);
      if (activeTab.value === "三维图层") {
        screen.screenType.folderId = folderId;
        layerInit();
      } else {
        screen.patrolScreen.folderId = folderId;
        patrolInit();
      }
    }
    //点击搜索文件
    function selectKeyword() {
      nextTick(() => {
        activeTab.value === "三维图层" && layerInit();
        activeTab.value === "三维图层" || patrolInit();
      });
    }

    /* 获取数据的所有下拉 */
    //获取下拉列表
    async function getLayerDropdown() {
      await dataServer.getDropdown().then((res) => {
        res.resultData && (screen.fileOptions = res.resultData);
      });
    }
    //获取数据来源下拉列表
    function getSourcetypes() {
      dataServer.getTypesDropdown().then(async (res) => {
        if (res.resultData) {
          screen.typesDropdown.typesList = res.resultData;
          screen.typesDropdown.typesValue = res.resultData[0].value;
          await getSourceList();
        }
      });
    }
    //获取机场、飞行器、人员下拉列表
    function getSourceList() {
      const params = { dataSourceType: screen.typesDropdown.typesValue };
      screen.typesDropdown.sourceList = [];
      dataServer.getSourceDropdown(params).then((res) => {
        const sourceData = res.resultData;
        if (sourceData) {
          for (let item in sourceData) {
            screen.typesDropdown.sourceList.push({
              builderId: item,
              laber: sourceData[item],
            });
          }
        }
      });
    }
    //获取巡检导入的文件下拉列表
    async function getImportFilelist() {
      let res = await dataServer.getImportDropdown();
      res.resultData && (screen.typesDropdown.fileTypeList = res.resultData);
      screen.importDropList = screen.typesDropdown.fileTypeList;
    }

    //获取文件夹面包屑列表
    async function getFileIndex(folderId) {
      await dataServer.getFileThree(folderId).then((res) => {
        screen.fileList[activeTab.value] = [];
        res.resultData && delayering(res.resultData);
      });
    }
    //多层单对象数据处理
    function delayering(objData) {
      screen.fileList[activeTab.value].push({
        folderId: objData.folderId,
        folderName: objData.folderName,
      });
      objData.childFolder && delayering(objData.childFolder);
    }

    /*修改文件夹面包屑数据 */
    function changeFolderId(folderId) {
      if (activeTab.value === "三维图层") {
        screen.screenType.folderId = folderId;
      } else {
        screen.patrolScreen.folderId = folderId;
      }
      getFileIndex(folderId);
    }

    /* 导入文件弹窗 */
    let isVisible = ref(false);
    function importFile() {
      isVisible.value = true;
    }

    /* 图层表格重新加载 */
    const layerTable = ref(null);
    function layerInit() {
      layerTable.value.getLayerlist();
    }

    /* 巡检表格重新加载 */
    const patrolTbale = ref(null);
    function patrolInit() {
      patrolTbale.value.initPatrolTable();
    }

    /* 删除事件 */
    function delectData(type) {
      type === "三维图层" && layerTable.value.deleteLayers();
      type === "巡检数据" && patrolTbale.value.deleteDatas();
    }
    /* 复制 */
    function copy() {
      patrolTbale.value.copyData();
    }
    /* 粘贴 */
    function paste() {
      patrolTbale.value.pasteData();
    }
    /* 导出 */
    function exportData() {
      patrolTbale.value.exportData();
    }
    /* 清空文件类型 */
    function clearType() {
      screen.patrolScreen.dataType = "";
    }

    //新建文件夹
    let createFolderData = reactive({
      creatName: "",
      folderPid: "",
      dialogTitle: t("818"),
    });
    let isCreateVisible = ref(false);

    const elFormRef = ref(null);
    //确定创建文件夹
    function createFolder() {
      elFormRef.value.validate((valid) => {
        if (valid) {
          if (!createFolderData.creatName.trim()) {
            return;
          }
          dataServer
            .createFolder(
              createFolderData.creatName,
              screen.screenType.folderId || folderHeadId["三维图层"]
            )
            .then((res) => {
              isCreateVisible.value = false;
              res.resultStatus && ElMessage.success(t("819"));
              layerInit();
            })
            .catch(() => {
              ElMessage.error(t("820"));
            });
          createFolderData.creatName = "";
        }
      });
    }
    //规则
    const nameRules = reactive({
      creatName: [
        { required: true, message: t("780"), trigger: "change" },
      ],
    });
    return {
      ...toRefs(screen),
      activeTab,
      isVisible,
      importFile,
      changeTab,
      getLayerDropdown,
      selectKeyword,
      layerInit,
      delectData,
      fileIndex,
      changeFolderId,
      getSourcetypes,
      getSourceList,
      getImportFilelist,
      patrolInit,
      paste,
      copy,
      exportData,
      patrolTbale,
      Delete,
      Upload,
      Search,
      Download,
      layerTable,
      clearType,
      folderHeadId,
      createFolder,
      createFolderData,
      isCreateVisible,
      nameRules,
      elFormRef,
    };
  },
});
</script>
<style scoped lang="less">
.navRight {
  height: 6.5625rem;
  margin-left: 23.125rem;
  background: rgba(0, 28, 49, 0.8);
  padding: 0.9375rem 2.0625rem 0.75rem 1.4375rem;
  .navRightTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #34ddf7;
      font-size: 1.125rem;
      display: flex;
      align-items: center;
      //重写面包屑样式
      .el-breadcrumb {
        font-size: 1rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        /deep/.el-breadcrumb__inner,
        /deep/.el-breadcrumb__separator {
          color: #34ddf7;
        }
      }
      span {
        cursor: pointer;
      }
    }
    .operation {
      display: inline-block;
      div {
        cursor: pointer;
        vertical-align: middle;
        margin-right: 1.3125rem;
        display: inline-block;
      }
      color: #0090da;
      span {
        vertical-align: middle;
      }
      div:last-child {
        margin-right: 0;
      }
    }
  }
  /* 右底筛选栏 */
  .navRightBom {
    font-size: 1rem;
    margin-top: 1.125rem;
    display: flex;
    align-items: center;
    position: relative;
    button {
      width: 7.1875rem;
      height: 2.5rem;
      background: #0d4460;
      border: 1px solid #318fb4;
      border-radius: 0px 0.25rem 0.25rem 0px;
      font-weight: 400;
      color: #ffffff;
      margin-right: 2.6875rem;
      cursor: pointer;
    }
    .select {
      height: 2.5625rem;
      width: 4.625rem;
      background: #0090da;
      margin-left: -1px;
      .el-icon {
        vertical-align: middle;
      }
    }
    /deep/.el-input {
      width: 15rem;
      height: 2.5rem;
      .el-input__wrapper {
        background-color: #0d4460;
        box-shadow: 0 0 0 0;
        border: 1px solid #51d2ff;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      border-radius: 0.25rem;

      &::placeholder {
        color: #a9b8c3;
      }
      color: #ffffff;
    }
    //文件夹
    .folder {
      width: 41.5625rem;
      height: 2.5rem;
      background: #0d4460;
      border: 1px solid #51d2ff;
      border-radius: 4px;
      overflow-y: hidden;
      overflow-x: auto;
      //重写面包屑样式
      .el-breadcrumb {
        line-height: 2.5rem;
        font-size: 1rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        /deep/.el-breadcrumb__inner,
        /deep/.el-breadcrumb__separator {
          color: #ffffff;
        }
      }
      span {
        cursor: pointer;
      }
      img {
        width: 1.5rem;
        margin-left: 1.0625rem;
        cursor: pointer;
        height: 1.1875rem;
      }
    }

    .fileTitle {
      color: #34ddf7;
      font-size: 1.125rem;
    }
    .activer {
      background: #0090da;
    }
  }
  .el-icon {
    vertical-align: middle;
  }
  .hover_opacity {
    position: absolute;
    right: 0;
    // margin-left: 4.5625rem;
  }
}
.context {
  margin-top: 0.5rem;
  height: calc(100% - 7.0625rem);
  background: rgba(0, 28, 49, 0.8);
}
.el-select {
  --el-select-border-color-hover: none;
  /deep/.el-input__wrapper {
    width: 23.125rem;
    border: 1px solid #36e5ff;
    border-radius: 0.25rem;
    background-color: @lightBackground;
    height: 2.5rem;
    box-shadow: 0 0 0 0;
  }
}
.selection {
  background: transparent;
  border: none;
  color: #35e1fb;
  outline: 0;
  padding: 4px 5px;
  box-shadow: 0;
}
/deep/.el-input__inner {
  color: #ffffff;
}
//创建文件夹
/deep/.fileForm {
  .el-form-item__label {
    color: #36e5ff;
  }
  .el-input__wrapper {
    background: #001c31;
    height: 40px;
    box-shadow: 0 0 0 0;
    border: 1px solid #36e5ff;
  }
}
.emptyTbody {
  font-size: 1.125rem;
  color: #fff;
  img {
    width: 17.5rem;
  }
}
</style>
