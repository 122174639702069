<template>
  <div class="table-wrapper">
    <div class="table_box">
      <el-table
        :data="tableData"
        class="el_table"
        :row-style="rowStyle"
        :header-cell-style="headerStyle"
        :cell-style="cellStyle"
        height="90%"
        @selection-change="handleSelectionChange"
        @row-click="handleRowClick"
        ref="eleTable"
      >
        <el-table-column type="selection" width="40" />
        <el-table-column type="index" :label="t('222')" width="80" />
        <el-table-column :label="t('779')" prop="name" show-overflow-tooltip />

        <el-table-column :label="t('1226')"
          ><template v-slot="{ row }">
            <span v-if="row.layerType">{{ row.layerType }} </span>

            <img
              :src="
                row.layerTypeName
                  ? ''
                  : require('@/asset/img/data-mange/file.png')
              "
              style="cursor: pointer"
              @click="enterFile(row)"
            /> </template
        ></el-table-column>

        <el-table-column :label="t('594')">
          <template v-slot="{ row }">
            <span v-if="row.layerTypeName">{{ row.layerTypeName }}</span>

            <span v-else>{{ t('813') }}</span>
          </template>
        </el-table-column>

        <el-table-column :label="t('785')" prop="dataSize" />
        <el-table-column :label="t('412')" prop="createTime" />
        <el-table-column :label="t('411')" prop="builderName" />

        <el-table-column :label="t('610')">
          <template v-slot="{ row }">
            <el-row>
              <el-col :span="11" v-if="row.layerType !== undefined">
                <span class="blue_btn" @click="checkDialog(row)">{{ t('384') }}</span>
              </el-col>
              <el-col :span="11" v-else>
                <span class="blue_btn" @click.prevent="enterFile(row)"
                  >{{ t('1227') }}</span
                >
              </el-col>
              <el-col :span="13">
                <span class="blue_btn" @click="dialogRename(row)">{{ t('776') }}</span>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <template #empty>
          <div class="emptyTbody">
            <div>
              <img src="@/asset/img/empty_data.png" :alt="t('223')" />
            </div>
            <p class="no_data">{{ t('223') }}</p>
          </div>
        </template>
      </el-table>
    </div>

    <!-- 底部分页 -->
    <el-pagination
      class="el_pageination"
      background
      @size-change="getLayerlist"
      @current-change="getLayerlist"
      v-model:currentPage="queryForm.pageNo"
      v-model:page-size="queryForm.pageSize"
      layout="total, prev, pager, next, sizes, jumper"
      :total="total"
    ></el-pagination>
  </div>
  <ele-dialog-img
    v-if="isVisebleImg"
    :isVisebleImg="isVisebleImg"
    @onClose="onClose"
  >
    <template v-slot:default>
      <div id="cesiumContainer" style="width: 100%; height: 100%"></div>
    </template>
  </ele-dialog-img>
  <!-- 重命名 -->
  <ele-dialog
    v-if="isVisible"
    v-model:isVisible="isVisible"
    :dialogTitle="editNameForm.dialogTitle"
    @on-cancel="isVisible = false"
    @on-add="rename"
  >
    <el-form
      label-position="top"
      :model="editNameForm"
      class="fileForm"
      :rules="nameRules"
    >
      <el-form-item :label="t('779')" prop="editName">
        <el-input
          :placeholder="t('780')"
          v-model.trim="editNameForm.editName"
          @keyup="
            editNameForm.editName = editNameForm.editName.replace(/[ ]/g, '')
          "
          maxlength="64"
        ></el-input>
      </el-form-item>
    </el-form>
  </ele-dialog>
</template>
<script>
import { t } from '../../../../languages';
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  inject,
  nextTick,
  watch,
  toRef,
  onMounted,
} from "vue";
import dataService from "@/network/data";
import ufi3d from "@/utils/ufi-cesium";
import EleDialogImg from "@/components/package/ele-dialogImg.vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default defineComponent({
  components: { EleDialogImg },
  data() {
    return {
      tableColumnArray: [
        { label: t("779"), prop: "layerName" },
        { label: t("594"), prop: "layerTypeName" },
        { label: t("785"), prop: "layerSize" },
        { label: t("412"), prop: "createTime" },
        { label: t("411"), prop: "builderName" },
      ],
    };
  },
  props: {
    keyword: String,
    screenType: [Object],
  },
  emits: ["changeFolderId"],

  setup(props, { emit }) {
    const eleTable = ref(null);
    function handleRowClick(row) {
      eleTable.value.toggleRowSelection(row);
    }

    /* 表格操作 */
    const tableData = ref([]);
    let isVisebleImg = ref(false);
    const paramsData = reactive({
      queryForm: {
        layerType: "",
        keyword: "",
        pageNo: 1,
        pageSize: 20,
        folderId: 1,
      },
      total: 0,
    });

    //接收props
    paramsData.queryForm = {
      ...paramsData.queryForm,
      ...toRefs(props.screenType),
      keyword: toRef(props, "keyword"),
    };

    //获取图层表格
    function getLayerlist() {
      paramsData.queryForm.folderId === "" &&
        (paramsData.queryForm.folderId = folderHeadId);

      dataService.getLayers(paramsData.queryForm).then((res) => {
        const resData = res.resultData;
        if (resData) {
          tableData.value = [...resData.folderList, ...resData.dataPage.data];
          tableData.value.forEach((item) => {
            item.createTime = item.createTime.split(" ")[0];
            item.name = item.layerName || item.folderName || "-";
            item.dataSize = item.layerSize || "-";
            item.builderName = item.builderName || "-";
          });
          paramsData.queryForm.pageNo = resData.dataPage.pageNo;
          paramsData.queryForm.pageSize = resData.dataPage.pageSize;
          paramsData.total = resData.dataPage.total;
        }
      });
    }

    //获取传入图层数据
    const Cesium = inject("Cesium");
    let viewer = null;
    let pointCloud = null;
    //查看操作弹窗
    async function checkDialog(row) {
      isVisebleImg.value = true;
      await nextTick(async () => {
        viewer = ufi3d.initCesium("cesiumContainer", {
          // 影像地图
          imageryProvider: new Cesium.UrlTemplateImageryProvider({
            url: window.g.satelliteMapUrl + "/{z}/{x}/{y}.png",
            tilingScheme: new Cesium.WebMercatorTilingScheme(),
          }),
          // 地形
          terrainProvider: setTerrain(),
        });
        /* 设置地形 */
        function setTerrain() {
          const mars3dTerrain = localStorage.getItem('mars3dTerrain')
          const terrainUrl = (mars3dTerrain == 'true') ? 'https://data.mars3d.cn/terrain' : window.g.terrainUrl
          return  new Cesium.CesiumTerrainProvider({
              url: terrainUrl
          })
        };
        if (row.layerType === "TIF") {
          // 正射图
          const customImagery = new ufi3d.CustomImagery(viewer);
          customImagery.addImagery(
            row.layerId,
            row.layerUrl,
            row.tifMetaInfo,
            true
          );
        } else {
          // 倾斜模型
          const pointCloud = new ufi3d.PointCloud(viewer);
          // 添加图层
          pointCloud.addPointCloud(row.layerId, row.layerUrl, 0, true);
        }
      });
    }

    //关闭查看弹窗
    function onClose() {
      isVisebleImg.value = false;
      /* 退出前清空cesium内容 */
      document.querySelector("#cesiumContainer").innerHTML = "";
      viewer.entities.removeAll();
      viewer = undefined;
    }

    /* 删除表格行数据 */
    let ids = reactive([]);
    function deleteLayers() {
      if (ids.length === 0) {
        ElMessage.warning(t("1228"));
        return;
      }

      let optionType = ""; //确定第一个数据类型
      let idType = ""; //id属性名
      let idsArr = [];

      optionType = ids[0].layerType !== undefined ? "layerType" : "folderTree";
      idType = optionType === "layerType" ? "layerId" : "folderId";

      ids.forEach((item) => {
        //判断表格不同数据是否同时勾选
        if (item[optionType] === undefined) {
          ids = [];
          idsArr = [];
          ElMessage.warning(t("1229"));
          return;
        } else {
          idsArr.push(item[idType]);
        }
      });
      if (idsArr.length === 0) {
        return;
      }

      ElMessageBox.confirm(t("1230"), t("130"), {
        type: "warning",
        confirmButtonText: t("218"),
        cancelButtonText: t("217"),
      })
        .then(() => {
          if (optionType === "layerType") {
            dataService.removeLayers({ ids: idsArr }).then((res) => {
              res.resultStatus && ElMessage.success(t("1231"));
              res.resultStatus && getLayerlist();
            });
          } else {
            dataService.removeLayersFolder({ ids: idsArr }).then((res) => {
              res.resultStatus && ElMessage.success(t("1231"));
              res.resultStatus && getLayerlist();
            });
          }
        })
        .catch(() => {
          ElMessage(t("549"));
        });
    }
    //选中表格行
    function handleSelectionChange(selection) {
      ids = selection;
    }

    //重命名数据
    let editNameForm = reactive({
      editName: "",
      layerId: "",
      dialogTitle: t("776"),
      editType: "",
    });
    //重命名弹窗
    let isVisible = ref(false);
    function dialogRename(lineData) {
      isVisible.value = true;
      editNameForm.editName = lineData.layerName || lineData.folderName;
      editNameForm.layerId = lineData.layerId || lineData.folderId;
      editNameForm.editType = lineData.layerType ? "layer" : "folder";
    }
    //重命名
    function rename() {
      if (!editNameForm.editName.trim()) {
        return;
      }
      let url = null;
      if (editNameForm.editType === "layer") {
        url = `/api/v1/layer/edit/${editNameForm.layerId}?layerName=${editNameForm.editName}`;
      } else {
        url = `/api/v1/folder/rename/${editNameForm.layerId}?folderName=${editNameForm.editName}`;
      }
      dataService
        .dataRename(url)
        .then((res) => {
          isVisible.value = false;
          res.resultStatus && ElMessage.success(t("1232"));
          getLayerlist();
        })
        .catch(() => {
          ElMessage.success(t("1233"));
        });
    }

    //规则
    const nameRules = reactive({
      editName: [
        { required: true, message: t("780"), trigger: "blur" },
      ],
    });

    //获取初始文件夹id
    onMounted(() => {
      getFolderHeadId();
    });

    let folderHeadId = null;
    async function getFolderHeadId() {
      dataService.getFolderHeadId().then((res) => {
        if (res instanceof Array) {
          res.forEach((item) => {
            if (item.desc === "三维图层") {
              folderHeadId = item.value.toString();
              paramsData.queryForm.folderId = folderHeadId;
            }
          });
          getLayerlist();
        }
      });
    }

    //监听筛选，表格
    watch(
      [() => paramsData.queryForm.layerType],
      (newValue, oldValue) => {
        getLayerlist();
      },
      { immediate: false, deep: true }
    );

    //重写样式
    const methods = reactive({
      rowStyle: () => {
        return {
          height: "48px",
          background: "rgba(0, 45, 74, 0.6)",
          fontSize: "1.0625rem",
          color: "#CCCCCC",
        };
      },
      cellStyle: (column) => {
        const columnIndex = column.columnIndex;
        if (columnIndex === 0) {
          return {
            background: "#0092CF",
            borderRadius: "8px 0px 0px 8px",
          };
        }
        return {
          textAlign: "center",
        };
      },
      headerStyle: () => {
        return {
          background: "rgba(1, 55, 82,.8)",
          color: "#36E5FF",
          fontSize: "1.125rem",
          fontWeight: "normal",
          textAlign: "center",
        };
      },
    });

    //进入表格文件夹
    function enterFile(row) {
      emit("changeFolderId", row.folderId);
      getLayerlist();
    }

    return {
      ...toRefs(methods),
      ...toRefs(paramsData),
      isVisebleImg,
      tableData,
      handleSelectionChange,
      deleteLayers,
      getLayerlist,
      checkDialog,
      onClose,
      rename,
      isVisible,
      dialogRename,
      editNameForm,
      nameRules,
      handleRowClick,
      enterFile,
      eleTable,
    };
  },
});
</script>
<style lang="less" scoped>
//表格
.table-wrapper {
  position: relative;
  height: 100%;
  /deep/ .el-table {
    background: none !important;
    .selection {
      cursor: pointer;
      background: transparent;
      border: none;
      outline: 0;
      padding: 0 1rem;
      margin-left: 1rem;
      box-shadow: 0;
      color: rgb(204, 204, 204);
      .item {
        color: black;
        text-align: center;
        border-radius: 0.25rem 0.25rem 0px 0px;
      }
    }
  }
  /deep/ .el-table::before {
    background: transparent;
  }
  .table_box {
    height: calc(100% - 5rem);
  }
  /deep/.el-table__inner-wrapper::before {
    height: 0;
  }
  /deep/ .el-table__body {
    border-spacing: 0 15px !important;
  }
  /deep/ .el-table__row {
    margin-top: 15px;
  }
  /deep/ .el-table__body tr:hover > td {
    background-color: #004375 !important;
  }
  /deep/.el-table__body tr.current-row > td {
    background-color: #004375 !important;
  }
  /deep/ .el-scrollbar__view {
    height: 100%;
  }
  /deep/ .el-table__body {
    border-spacing: 0 4px !important;
  }
}
// 分页
.el_pageination {
  position: absolute;
  right: 0;
  //总页数样式设置
  /deep/ .el-pagination__total {
    color: #ffffff;
  }
  //前往（）页
  /deep/ .el-pagination__jump {
    color: #ffffff;
    margin-left: 0;
  }
  //分页条数选择背景，前往（）页jump背景
  /deep/ .el-input__wrapper {
    background: #001c31;
    box-shadow: 0 0 0 0;
    margin-left: 5px;
    border: 1px solid #36e5ff;
  }
  //分页条数选择，前往（）页jump文字颜色
  /deep/ .el-input__inner {
    color: #ffffff;
  }
}
/deep/.el_pageination.is-background {
  .el-pager li:not(.disabled):hover {
    background: #0090da !important;
    border-color: #0090da;
    color: #fff;
  }
  //活动的按钮
  .el-pager li:not(.disabled).is-active {
    background: #0090da;
    border: 1px solid #0090da;
  }
  .btn-prev {
    background: rgba(0, 28, 49, 0.8);
    border: 1px solid #36e5ff;
  }
  .btn-next {
    background: rgba(0, 28, 49, 0.8);
    border: 1px solid #36e5ff;
  }
  .el-pager li {
    background: rgba(0, 28, 49, 0.8);
    border: 1px solid #36e5ff;
    color: #fff;
  }
}
/deep/.el-input.el-input--suffix {
  border: 0;
}
//重命名
/deep/.fileForm {
  .el-form-item__label {
    color: #36e5ff;
  }
  .el-input__wrapper {
    background: #001c31;
    height: 40px;
    box-shadow: 0 0 0 0;
    border: 1px solid #36e5ff;
  }
}
.emptyTbody {
  font-size: 1.125rem;
  color: #fff;
  img {
    width: 17.5rem;
  }
}
</style>