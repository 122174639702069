<template>
  <div class="table-wrapper">
    <div class="table_box">
      <el-table
        :data="tableData"
        class="el_table"
        :row-style="rowStyle"
        :header-cell-style="headerStyle"
        :cell-style="cellStyle"
        height="90%"
        @selection-change="handleSelectionChange"
        @row-click="handleRowClick"
        ref="eleTable"
      >
        <el-table-column type="selection" width="40" />
        <el-table-column type="index" :label="t('222')" width="80" />
        <el-table-column :label="t('779')" prop="name" show-overflow-tooltip />

        <el-table-column :label="t('1226')"
          ><template v-slot="{ row }">
            <span v-if="row.dataSuffix">{{ row.dataSuffix }} </span>

            <img
              :src="
                row.dataSuffix ? '' : require('@/asset/img/data-mange/file.png')
              "
              style="cursor: pointer"
              @click="enterFile(row)"
            /> </template
        ></el-table-column>

        <el-table-column :label="t('594')" prop="dataType">
          <template v-slot="{ row }">
            <select
              v-model="row.dataType"
              class="selection"
              v-if="row.dataType"
              @change="updateDataType(row.dataId, row.dataType)"
            >
              <option
                class="item"
                v-for="item in fileTypeList"
                :key="item.value"
                :label="item.desc"
                :value="item.value"
              ></option>
            </select>
            <span v-else>{{ t('813') }}</span>
          </template>
        </el-table-column>

        <el-table-column :label="t('785')" prop="dataSize" />
        <el-table-column :label="t('412')" prop="createTime" />
        <el-table-column :label="t('411')" prop="builderName" />

        <el-table-column :label="t('610')">
          <template v-slot="{ row }">
            <el-row>
              <el-col :span="11" v-if="row.dataSuffix !== undefined">
                <span class="blue_btn" @click="checkMessage(row)">{{ t('384') }}</span>
              </el-col>
              <el-col :span="11" v-else>
                <span class="blue_btn" @click="enterFile(row)">{{ t('1227') }}</span>
              </el-col>
              <el-col :span="13">
                <span class="blue_btn" @click="dialogRename(row)">{{ t('776') }}</span>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <template #empty>
          <div class="emptyTbody">
            <div>
              <img src="@/asset/img/empty_data.png" :alt="t('223')" />
            </div>
            <p class="no_data">{{ t('223') }}</p>
          </div>
        </template>
      </el-table>
    </div>

    <!-- 底部分页 -->
    <el-pagination
      class="el_pageination"
      background
      @size-change="initPatrolTable"
      @current-change="initPatrolTable"
      v-model:currentPage="pageNo"
      v-model:page-size="pageSize"
      layout="total, prev, pager, next, sizes, jumper"
      :total="total"
    ></el-pagination>
  </div>
  <!-- 查看弹窗 -->
  <ele-dialog-img
    v-if="isVisebleImg"
    :isVisebleImg="isVisebleImg"
    :openType="'picture'"
    :dataUrl="dataUrl"
    @onClose="onClose"
  >
  </ele-dialog-img>

    <!-- video弹窗 -->
  <ele-dialog-video
    v-if="isVisebleVideo"
    :isVisebleVideo="isVisebleVideo"
    :dataUrl="dataUrl"
    @onClose="onClose"
  >
  </ele-dialog-video>

  <!-- 重命名弹窗 -->
  <ele-dialog
    :isVisible="isRename"
    :dialogTitle="editNameForm.dialogTitle"
    @on-cancel="isRename = false"
    @on-add="rename"
  >
    <el-form
      label-position="top"
      :model="editNameForm"
      class="fileForm"
      :rules="nameRules"
    >
      <el-form-item :label="t('779')" prop="editName">
        <el-input
          :placeholder="t('780')"
          v-model.trim="editNameForm.editName"
          maxlength="64"
        ></el-input>
      </el-form-item>
    </el-form>
  </ele-dialog>
</template>
<script>
import { t } from '../../../../languages';
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  toRef,
  watch,
  onMounted,
} from "vue";
import dataService from "@/network/data";
import EleDialogImg from "@/components/package/ele-dialogImg.vue";
import EleDialogVideo from "../../../package/ele-dialog-video.vue";
import { ElNotification, ElMessage, ElMessageBox } from "element-plus";
import download from "@/network/download";

export default defineComponent({
  name: "patrolTable",
  data() {
    return {
      headerButton: [],
    };
  },
  props: {
    patrolScreen: [Object],
    fileTypeList: {
      type: Array,
      defalut: () => [],
    },
    keyword: String,
    // folderHeadId: String,
  },
  emits: ["changeFolderId"],
  components: { EleDialogImg, EleDialogVideo },
  setup(props, { emit }) {
    //表格数据
    const state = reactive({
      // tailSelection: false, //序号判断
      tableData: [],
      total: 0, //表格总数
    });
    //分页参数
    const pageParams = reactive({
      pageNo: 1,
      pageSize: 20,
    });

    /* 获取巡检表格数据 */
    function initPatrolTable() {
      let params = { ...screenParams, ...pageParams };
      params.folderId === "" && (params.folderId = folderHeadId);
      //去除没有值的参数
      for (let item in params) {
        params[item] || delete params[item];
      }
      dataService.getPatrolTable(params).then((res) => {
        const resData = res.resultData;
        state.tableData = [];
        if (resData) {
          state.tableData = [...resData.folderList, ...resData.dataPage.data];
          pageParams.pageNo = resData.dataPage.pageNo;
          pageParams.pageSize = resData.dataPage.pageSize;
          state.total = resData.dataPage.total;
          state.tableData.forEach((item) => {
            item.createTime = item.createTime.split(" ")[0];
            item.name = item.dataName || item.folderName || "-";
            item.dataSize = item.dataSize || "-";
            item.builderName = item.builderName || "-";
          });
        }
      });
    }

    //重命名数据
    let editNameForm = reactive({
      editName: "",
      dataId: "",
      dialogTitle: t("776"),
      dataType: "",
    });
    //重命名弹窗
    let isRename = ref(false);
    function dialogRename(lineData) {
      isRename.value = true;
      editNameForm.editName = lineData.dataName || lineData.folderName;
      editNameForm.dataId = lineData.dataId || lineData.folderId;
      editNameForm.dataType =
        lineData.dataSuffix !== undefined ? "dataSuffix" : "folderTree";
    }
    //重命名
    function rename() {
      if (!editNameForm.editName.trim()) {
        return;
      }
      let url1 = `/api/v1/data/rename/${editNameForm.dataId}?dataName=${editNameForm.editName}`; //普通数据
      let url2 = `/api/v1/folder/rename/${editNameForm.dataId}?folderName=${editNameForm.editName}`; //文件夹
      let url = editNameForm.dataType === "dataSuffix" ? url1 : url2;
      dataService.dataRename(url).then((res) => {
        isRename.value = false;
        res.resultStatus && ElMessage.success(t("1232"));
        initPatrolTable();
      });
    }

    //规则
    const nameRules = reactive({
      editName: [
        { required: true, message: t("780"), trigger: "blur" },
      ],
    });

    //修改类型
    function updateDataType(id, dataType) {
      dataService.updatePhototype(id, dataType).then((res) => {
        res.resultStatus && ElMessage.success(t("1234"));
        initPatrolTable();
      });
    }

    //传递参数
    let screenParams = reactive({
      folderId: "", //文件夹id
      builderId: "", //下拉框所属id
      dataType: "", //类型
      endTime: "", //结束时间
      startTime: "", //开始时间
      keyword: "",
    });

    //进入表格文件夹
    async function enterFile(row) {
      // screenParams.folderId = row.folderId;
      emit("changeFolderId", row.folderId);
      initPatrolTable();
    }

    /* 组件传参 */
    const { fileTypeList, keyword } = toRefs(props);
    screenParams = reactive({
      ...screenParams,
      ...toRefs(props.patrolScreen),
    });
    screenParams.keyword = keyword;

    //获取头部文件夹id
    let folderHeadId = null;
    async function getFolderHeadId() {
      dataService.getFolderHeadId().then((res) => {
        if (res instanceof Array) {
          res.forEach((item) => {
            if (item.desc === "巡检数据") {
              folderHeadId = item.value.toString();
            }
          });
          initPatrolTable();
        }
      });
    }
    onMounted(() => {
      getFolderHeadId();
    });

    //监听筛选数据
    watch(
      [
        () => screenParams.builderId,
        () => screenParams.dataType,
        () => screenParams.endTime,
        () => screenParams.startTime,
      ],
      () => {
        initPatrolTable();
      }
    );

    //单行查看
    let isVisebleImg = ref(false);
    let isVisebleVideo = ref(false);
    let checkImg = reactive({
      dataUrl: "",
      imgType: "",
    });
    function checkMessage(lineData) {
      if(lineData.dataSuffix === 'MP4'){
        isVisebleVideo.value = true;
      }else{
        if (lineData.dataType === "PANORAMIC") {
          checkImg.imgType = t("221");
        } else if (lineData.dataType === "PHOTO") {
          checkImg.imgType = t("684");
        } else {
          checkImg.imgType = t("220");
        }
        isVisebleImg.value = true;
      }
      checkImg.dataUrl = lineData.dataUrl;
    }
    //关闭弹窗
    function onClose() {
      isVisebleImg.value = false;
      isVisebleVideo.value = false;
    }

    //表格选中
    let ids = reactive([]);
    let typeIdentical = ref("dataId"); //选中数据类型判断
    function handleSelectionChange(selection) {
      let optionType = ""; //确定第一个数据类型
      let idType = ""; //id属性名
      isCopy = false;
      ids = [];
      if (selection.length) {
        optionType =
          selection[0].dataSuffix !== undefined ? "dataSuffix" : "folderTree";
        idType = optionType === "dataSuffix" ? "dataId" : "folderId";
        selection.forEach((item) => {
          //判断表格不同数据是否同时勾选
          if (item[optionType] === undefined) {
            typeIdentical.value = "";
            ids = [];
            return;
          } else {
            ids.push(item[idType]);
            typeIdentical.value = idType;
          }
        });
      }
    }
    //点击选中
    const eleTable = ref(null);
    function handleRowClick(row) {
      eleTable.value.toggleRowSelection(row);
    }

    //删除
    function deleteDatas() {
      if (ids.length === 0) {
        if (typeIdentical.value === "") {
          openNotification(t("1229"));
          return;
        }
        openNotification(t("1235"));
        return;
      }
      ElMessageBox.confirm(t("1230"), t("130"), {
        type: "warning",
        confirmButtonText: t("218"),
        cancelButtonText: t("217"),
      })
        .then(async () => {
          const url =
            typeIdentical.value === "dataId"
              ? "/api/v1/data/list/remove"
              : "/api/v1/folders/remove";
          const res = await dataService.removeDatas(url, { ids: ids });
          res.resultStatus && ElMessage.success(t("1231"));
          res.resultStatus && initPatrolTable();
        })
        .catch(() => {
          ElMessage(t("549"));
        });
    }

    //复制
    let isCopy = false;
    let copyIds = reactive([]);
    function copyData() {
      if (typeIdentical.value === "folderId" || typeIdentical.value === "") {
        openNotification(t("1236"));
        return;
      }
      if (copyIds.length === 0 && ids.length === 0) {
        openNotification(t("1237"));
        return;
      }
      if (ids.length === 0 && copyIds.length !== 0) {
        openNotification(t("1238"));
        return;
      }
      isCopy = true;
      copyIds = ids;
      openNotification(t("1239"), "success");
    }

    //粘贴
    function pasteData() {
      if (copyIds.length === 0) {
        openNotification(t("1240"));
        return;
      }
      // if (isCopy === false) {
      //   openNotification(t("1240"));
      //   return;
      // }
      dataService
        .pasteDatas(screenParams.folderId, { ids: copyIds })
        .then((res) => {
          initPatrolTable();
          res.resultStatus && ElMessage.success(t("1241"));
        });
    }

    //导出文件
    async function exportData() {
      if (typeIdentical.value === "folderId" || typeIdentical.value === "") {
        openNotification(t("1242"));
        return;
      }
      if (ids.length === 0) {
        openNotification(t("851"));
        return;
      }
      await download.downloadSource(
        "/api/v1/data/export",
        { ids: ids },
        "application/zip",
        "post"
      );
    }

    //Notification框
    function openNotification(message, success) {
      ElNotification({
        title: t("130"),
        message,
        duration: 2000,
        type: success || "warning",
      });
    }
    //重写样式
    const methods = reactive({
      rowStyle: () => {
        return {
          height: "48px",
          background: "rgba(0, 45, 74, 0.6)",
          fontSize: "1.0625rem",
          color: "#CCCCCC",
        };
      },
      cellStyle: (column) => {
        const columnIndex = column.columnIndex;
        if (columnIndex === 0) {
          return {
            background: "#0092CF",
            borderRadius: "8px 0px 0px 8px",
          };
        }
        return {
          textAlign: "center",
        };
      },
      headerStyle: () => {
        return {
          background: "rgba(1, 55, 82,.8)",
          color: "#36E5FF",
          fontSize: "1.125rem",
          fontWeight: "normal",
          textAlign: "center",
        };
      },
    });

    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(pageParams),
      ...toRefs(checkImg),
      editNameForm,
      screenParams,
      initPatrolTable,
      // fileTypeList,
      checkMessage,
      enterFile,
      onClose,
      handleSelectionChange,
      deleteDatas,
      exportData,
      rename,
      dialogRename,
      updateDataType,
      handleRowClick,
      isRename,
      isVisebleImg,
      copyData,
      pasteData,
      nameRules,
      eleTable,
      isVisebleVideo,
    };
  },
});
</script>

<style lang="less" scoped>
//表格
.table-wrapper {
  position: relative;
  height: 100%;
  /deep/ .el-table {
    background: none !important;
    .selection {
      cursor: pointer;
      background: transparent;
      border: none;
      outline: 0;
      padding: 0 1rem;
      margin-left: 1rem;
      box-shadow: 0;
      color: rgb(204, 204, 204);
      .item {
        color: black;
        text-align: center;
        border-radius: 0.25rem 0.25rem 0px 0px;
      }
    }
  }
  /deep/ .el-table::before {
    background: transparent;
  }
  .table_box {
    height: calc(100% - 5rem);
  }
  /deep/.el-table__inner-wrapper::before {
    height: 0;
  }
  /deep/ .el-table__body {
    border-spacing: 0 15px !important;
  }
  /deep/ .el-table__row {
    margin-top: 15px;
  }
  /deep/ .el-table__body tr:hover > td {
    background-color: #004375 !important;
  }
  /deep/.el-table__body tr.current-row > td {
    background-color: #004375 !important;
  }
  /deep/ .el-scrollbar__view {
    height: 100%;
  }
  /deep/ .el-table__body {
    border-spacing: 0 4px !important;
  }
}
// 分页
.el_pageination {
  position: absolute;
  right: 0;
  //总页数样式设置
  /deep/ .el-pagination__total {
    color: #ffffff;
  }
  //前往（）页
  /deep/ .el-pagination__jump {
    color: #ffffff;
    margin-left: 0;
  }
  //分页条数选择背景，前往（）页jump背景
  /deep/ .el-input__wrapper {
    background: #001c31;
    box-shadow: 0 0 0 0;
    margin-left: 5px;
    border: 1px solid #36e5ff;
  }
  //分页条数选择，前往（）页jump文字颜色
  /deep/ .el-input__inner {
    color: #ffffff;
  }
}
/deep/.el_pageination.is-background {
  .el-pager li:not(.disabled):hover {
    background: #0090da !important;
    border-color: #0090da;
    color: #fff;
  }
  //活动的按钮
  .el-pager li:not(.disabled).is-active {
    background: #0090da;
    border: 1px solid #0090da;
  }
  .btn-prev {
    background: rgba(0, 28, 49, 0.8);
    border: 1px solid #36e5ff;
  }
  .btn-next {
    background: rgba(0, 28, 49, 0.8);
    border: 1px solid #36e5ff;
  }
  .el-pager li {
    background: rgba(0, 28, 49, 0.8);
    border: 1px solid #36e5ff;
    color: #fff;
  }
}
/deep/.el-input.el-input--suffix {
  border: 0;
}
//重命名
/deep/.fileForm {
  .el-form-item__label {
    color: #36e5ff;
  }
  .el-input__wrapper {
    background: #001c31;
    height: 40px;
    box-shadow: 0 0 0 0;
    border: 1px solid #36e5ff;
  }
}
.emptyTbody {
  font-size: 1.125rem;
  color: #fff;
  img {
    width: 17.5rem;
  }
}
</style>
