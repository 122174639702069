 <template>
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="t('1199')"
    :dialogWidth="'504px'"
    :dialogTop="'506px'"
    :hasFooter="!fileData.isLoading"
    @on-cancel="$emit('update:isVisible', false)"
    @on-add="confirm(uploadFormRef)"
  >
    <el-form
      label-position="top"
      class="fileForm"
      :rules="fileRules"
      ref="uploadFormRef"
      :model="fileData"
    >
      <el-form-item :label="t('817')" prop="layerType">
        <el-select v-model="fileData.layerType" :placeholder="t('1200')">
          <el-option
            v-for="item in importDropList"
            :key="item.value"
            :label="item.desc"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('1201')" prop="layerName">
        <el-input
          :placeholder="t('780')"
          v-model.trim="fileData.layerName"
          @keyup="fileData.layerName = fileData.layerName.replace(/[ ]/g, '')"
          maxlength="64"
        ></el-input>
      </el-form-item>
      <div
        class="deleteFile"
        @click="deleteFile"
        v-show="fileData.fileName !== ''"
      >
        <el-icon color="red"><Delete></Delete></el-icon>
        <span>&nbsp;{{ t('210') }}</span>
      </div>
      <el-form-item :label="fileTitle" prop="fileUpload">
        <div style="width: 100%">
          <el-upload
            class="upload-demo"
            :auto-upload="false"
            :on-change="selectFile"
            :limit="1"
            :accept="acceptType"
            :http-request="httpUploadFile"
            ref="uploadRef"
            v-show="fileData.fileName === ''"
            :file-list="fileData.fileUpload"
            :show-file-list="false"
            ><span>{{ t('778') }}</span>
          </el-upload>
          <div v-show="fileData.fileName !== ''" class="fileShow">
            <img src="@/asset/img/data-mange/uploadFile.png" />
            {{ fileData.fileName }}
            <el-progress
              v-show="fileData.isLoading"
              :percentage="fileData.value"
              color="#67c23a"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item v-if="fileData.isLoading" class="lastClose">
        <el-button
          class="common-confirm-btn confirm_btn closed"
          size="large"
          @click="closeDialog"
          >{{ t('408') }}</el-button
        >
      </el-form-item>
    </el-form>
  </ele-dialog>
</template>
<script>
import { t } from '../../../../languages';
import eleDialog from "@/components/package/ele-dialog.vue";
import { ElMessage, ElNotification, ElMessageBox } from "element-plus";
import { defineComponent, reactive, ref, toRefs, onMounted } from "vue";
import SparkMD5 from "spark-md5";
import common from "@/network/common";
import dataService from "@/network/data";
export default defineComponent({
  components: { eleDialog },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    importDropList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    types: {
      type: String,
      default: () => "巡检数据",
    },
    folderId: {
      type: String,
      default: () => "0",
    },
  },
  emits: ["layerInit", "update:isVisible", "patrolInit"],
  setup(props, { emit }) {
    /* 导入 */
    let fileData = reactive({
      layerType: "", //选择文件
      fileName: "", //文件名称
      layerName: "",
      fileExist: false,
      fileMd5: "",
      value: 0, // 进度条
      isLoading: false, //进度条显示
      fileUpload: [],
    });
    let fileReader = null; //文件读取流对象
    let notification = null;
    let fileSliceList = []; //存放文件分块数组
    let finishReqNum = 0; //文件分块数量
    let saveTime = 0; //保存变量
    const uploadFormRef = ref(null);
    const uploadRef = ref(null);

    /* 成果数据类型判断 */
    let dataTypes = reactive({
      // dataType: "",
      fileTitle: "",
      checkMd5Url: "", //检查md5存在接口
      saveDataUrl: "", //保存数据接口
      importDataUrl: "", // 数据上传接口
      acceptType: "", //接受类型
      initFun: "",
    });
    //监听导入是哪个页面触发，可用onMounted做
    onMounted(() => {
      fileData.layerType = "";
      fileData.layerName = "";
      fileData.isLoading = false;
      fileData.fileUpload = [];
      fileData.fileName = "";
      fileData.fileMd5 = "";
      if (props.types === "三维图层") {
        dataTypes.fileTitle = t("1202");
        dataTypes.checkMd5Url = "/api/v1/layer/file/";
        dataTypes.importDataUrl = "/api/v1/layer/upload/chunk";
        dataTypes.saveDataUrl = "/api/v1/layer/save";
        dataTypes.acceptType = ".zip";
        dataTypes.initFun = "layerInit";
      } else {
        dataTypes.fileTitle = t("1203");
        dataTypes.checkMd5Url = "/api/v1/data/file/";
        dataTypes.importDataUrl = "/api/v1/data/upload/chunk";
        dataTypes.saveDataUrl = "/api/v1/data/save";
        dataTypes.acceptType = "";
        dataTypes.initFun = "patrolInit";
      }
    });

    /* 检查文件是否上传 */
    function doIncrementalTest(uploadFile) {
      // 清除正在读取的文件流和Notification 通知
      if (fileReader !== null) {
        fileReader.abort();
      }
      if (notification !== null) {
        notification.close();
      }
      //这里需要用到File的slice( )方法，以下是兼容写法
      var blobSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice,
        file = uploadFile,
        chunkSize = 5242880, // 以每片5MB大小来逐次读取
        chunks = Math.ceil(file.size / chunkSize), //分多少块
        currentChunk = 0,
        spark = new SparkMD5(); //创建SparkMD5的实例

      fileReader = new FileReader();
      notification = ElNotification({
        title: t("130"),
        message: t("1204"),
        duration: 0,
      });
      fileReader.onload = async function (e) {
        spark.appendBinary(e.target.result); // append array buffer
        currentChunk += 1;
        if (currentChunk < chunks) {
          loadNext(); //把分的块加到数组
        } else {
          fileData.fileMd5 = spark.end();
          // 检查md5存在与否
          const data = await common.checkFileMD5(
            dataTypes.checkMd5Url,
            fileData.fileMd5
          );
          if (data instanceof Object && data.resultData.finished === true) {
            fileData.fileExist = true;
          } else {
            fileData.fileExist = false;
          }
          notification.close();
          notification = null;
        }
      };

      //文件读取失败
      fileReader.onerror = function () {
        console.log("something went wrong");
      };

      //将文件分块，数据添加到数组
      function loadNext() {
        var start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize;
        const tmp = blobSlice.call(file, start, end);
        tmp.position = start;
        fileSliceList.push(tmp);
        fileReader.readAsBinaryString(tmp);
      }
      loadNext();
    }

    //选择文件
    // let fileUpload = ref([]);
    const filetype = ["image/jpg", "image/png", "image/svg", "image/jpeg"];
    function selectFile(file) {
      console.log("----打印：", fileData.fileUpload);
      uploadFormRef.value.validateField("fileUpload", () => {});
      saveTime = 0;
      if (file.status !== "success") {
        if (file.raw) {
          doIncrementalTest(file.raw);
        }
        const name = file.name;
        if (name !== "") {
          fileData.fileName = name;
          const res = name.split(".");
          fileData.layerName = res[0];
        }
        if (props.types !== "三维图层" && !filetype.includes(file.raw.type)) {
          ElMessage.warning(t("1205"));
        }
      }
    }
    //删除选择文件
    function deleteFile() {
      removeFile();
      uploadFormRef.value.validateField("fileUpload", () => {});
    }
    // 自定义上传，判断直接保存或切片上传
    async function httpUploadFile(option) {
      fileData.isLoading = true;
      if (typeof XMLHttpRequest === "undefined") {
        return;
      }
      if (fileData.fileExist === true) {
        // 文件已存在，直接保存
        const params =
          props.types === "三维图层"
            ? {
                layerName: fileData.layerName,
                layerType: fileData.layerType,
                folderId: props.folderId,
                md5: fileData.fileMd5,
              }
            : {
                dataName: fileData.layerName,
                dataType: fileData.layerType,
                folderId: props.folderId,
                md5: fileData.fileMd5,
              };
        dataService.saveLayer(dataTypes.saveDataUrl, params).then((res) => {
          fileUploadSuccess(res.resultStatus);
        });
      } else {
        const requestParamList = [];
        fileSliceList.forEach((currentValue, index) => {
          let params = {
            chunkIndex: index,
            chunkNum: fileSliceList.length,
            fileName: fileData.fileName,
            md5: fileData.fileMd5,
            size: option.file.size,
            file: currentValue,
          };
          requestParamList.push(params);
        });
        requestUpload(requestParamList);
      }
    }
    /* 文件上传 */
    function requestUpload(requestParamList) {
      dataService
        .importLayers(dataTypes.importDataUrl, requestParamList[finishReqNum])
        .then((res) => {
          if (res.resultStatus === true) {
            // 非200状态码 进行上传重试
            finishReqNum++;
            fileData.value = Number(
              ((finishReqNum / requestParamList.length) * 100).toFixed(2)
            );
          } else {
            ElMessage.info(t("1206"));
          }
          if (finishReqNum === requestParamList.length) {
            // 代表所有请求完成
            if (res.resultData.finished === true) {
              // 上传成功
              requestSave();
            } else {
              // 上传失败
              ElMessage.error(t("1207"));
            }
          } else {
            if (res.resultStatus === true) {
              if (fileData.isLoading) {
                // 上传状态中才可继续上传，否则为取消上传
                requestUpload(requestParamList);
              }
            } else {
              // 上传失败
              ElMessage.error(t("1208"));
            }
          }
        })
        .catch((res) => {
          finishReqNum++;
          ElMessage.error(t("1209"));
          console.log(t("1210") + res);
        });
    }

    //文件上传成功进度条
    function fileUploadSuccess(resultStatus) {
      if (resultStatus === true) {
        ElMessage.success(t("751"));
        emit("update:isVisible", false);
        emit(dataTypes.initFun);
        fileData.layerType = "";
        fileData.layerName = "";
        fileData.fileName = "";
        // 进度条为0
        fileData.value = 0;
        fileData.isLoading = false;
        fileData.fileUpload = [];
      } else {
        emit("update:isVisible", false);
        ElMessage({
          message: t("1211"),
          type: "error",
          grouping: true,
        });
      }
    }
    //上传后保存图层
    function requestSave() {
      saveTime++;
      const params =
        props.types === "三维图层"
          ? {
              layerName: fileData.layerName,
              layerType: fileData.layerType,
              folderId: props.folderId,
              md5: fileData.fileMd5,
            }
          : {
              dataName: fileData.layerName,
              dataType: fileData.layerType,
              folderId: props.folderId,
              md5: fileData.fileMd5,
            };
      dataService.saveLayer(dataTypes.saveDataUrl, params).then((res) => {
        if (res.resultStatus === true) {
          ElMessage.success(t("792"));
        } else {
          if (saveTime < 3) {
            alert(t("225"));
            requestSave();
          }
          // ElMessage.error("保存失败1");
        }
        fileUploadSuccess(res.resultStatus);
      });
    }
    //清除正在上传
    function removeFile() {
      fileData.fileName = "";
      fileData.layerName = "";
      fileData.fileUpload = [];

      // 清除正在读取的文件流和Notification 通知
      if (fileReader !== null) {
        fileReader.abort();
      }
      if (notification !== null) {
        notification.close();
      }
      finishReqNum = 0;
      fileSliceList = [];
    }
    /* 上传中关闭弹窗 */
    function closeDialog() {
      ElMessageBox.confirm(
        t("1212"),
        t("130"),
        {
          confirmButtonText: t("1213"),
          cancelButtonText: t("1214"),
          distinguishCancelAndClose: true,
        }
      )
        .then(() => {
          ElMessage(t("1215"));
          emit("update:isVisible", false);
        })
        .catch((action) => {
          if (action === "cancel") {
            fileData.isLoading = false;
            ElMessage(t("1216"));
            emit("update:isVisible", false);
            removeFile();
          }
        });
    }

    //表单校验
    const fileRules = reactive({
      layerType: [
        {
          required: true,
          message: t("1200"),
          trigger: "change",
        },
      ],
      layerName: [
        {
          required: true,
          message: t("780"),
          trigger: ["change", "blur"],
        },
      ],
      fileUpload: [
        {
          required: true,
          message: t("1217"),
          trigger: "change",
        },
      ],
    });
    //确认导入
    async function confirm(formEl) {
      formEl.validate(async (valid, fields) => {
        if (valid && notification === null && fileData.fileMd5 !== "") {
          await uploadRef.value.submit();
        } else if (notification !== null) {
          ElMessage.warning(t("1218"));
          return false;
        } else {
          // fileData.fileMd5 === "" &&
          //   ElMessage.warning("文件Md5为空,请重新选择");
          return false;
        }
      });
    }

    return {
      fileData,
      selectFile,
      deleteFile,
      closeDialog,
      fileRules,
      confirm,
      uploadRef,
      uploadFormRef,
      httpUploadFile,
      removeFile,

      ...toRefs(dataTypes),
    };
  },
});
</script>

<style lang="less" scoped>
/deep/.fileForm {
  .el-form-item__label {
    color: #36e5ff;
  }
  .el-upload,
  .fileShow {
    width: 100%;
    height: 113px;
    background: #001c31;
    border: 1px solid #51d2ff;
  }
  .el-select {
    width: 100%;
    --el-select-input-focus-border-color: none;
    --el-select-border-color-hover: none;
  }
  .el-input__wrapper {
    background: #001c31;
    height: 40px;
    box-shadow: 0 0 0 0;
    border: 1px solid #36e5ff;
  }
  .deleteFile {
    position: absolute;
    right: 20px;
    .el-icon {
      vertical-align: middle;
    }
    cursor: pointer;
  }
  .fileShow {
    text-align: center;
    padding-top: 1.5rem;
    img {
      display: block;
      margin: auto;
    }
  }
  .el-progress__text {
    line-height: 0;
  }
  .closed {
    background: #00959b;
    border-color: #00f5ff;
    color: #ffffff;
    padding: 0 30px;
    margin-bottom: 8px;
  }
  .lastClose .el-form-item__content {
    justify-content: end;
    margin-bottom: 1.1875rem;
  }
}
</style>
